/* 	------------------------------------------------------- */
/* Global */
/* 	------------------------------------------------------- */
body {
	color: black;
	font-family: 'Barlow', sans-serif;
	font-size: 1.1em;
}

.container {
	-webkit-transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
}

.bg-ec-primary-dark {
	background-color: #062f6e;
}

.bg-ec-primary-light {
	background-color: #0093C9;
}

.bg-ec-secondary-dark {
	background-color: #8C88A3;
}

.bg-ec-secondary-light {
	background-color: #95E1BF;
}

.bc-ec-gray {
	background-color: #e8e7ed;
}

.bc-ec-purple {
	background-color: #8c87a3;
}

.divider-row {
	height: .75rem;
}


/* 	------------------------------------------------------- */
/* 	Navigation */
/* 	------------------------------------------------------- */
.logo {
	margin: 10px 0px 5px 0px;
	width: 200px;
	transition: all .25s;
}

.ecnav {
	border: 0;
}

.navbar-light .navbar-nav .nav-link {
	color: #062f6e;
	font-weight: bold;
}

.navbar-light .navbar-nav .nav-link:hover {
	color: #0093C9;
	font-weight: bold;
	transition: all .25s;
}

.navbar .active {
	text-decoration: underline;
	color: #0093C9 !important;
}

.contact-us-nav {
	line-height: 1.1rem;
}

.contact-us-nav a {
	color: #8C88A3;
	font-style: italic;
	font-weight: bold;
}

.contact-us-nav a:hover {
	text-decoration: none;
	color: #0093C9 !important;
}

.contact-us-nav a.active {
	text-decoration: underline;
	color: #0093C9 !important;
}

#upBtn {
	opacity:0;
	position: fixed;
	bottom: 20px;
	right: 30px;
	z-index: 99;
	border: none; 
	outline: none; 
	background-color: #95E1BF; 
	color: white; 
	padding: 10px; 
	border-radius: 50%;
	font-size: 1rem;
	cursor:default; 
	transition: all .25s;
  }
  
  #upBtn:hover {
	background-color: #062f6e; 
	box-shadow: 0px 0px 7px white;
  }



/* 	------------------------------------------------------- */
/* 	Home banner
/* 	------------------------------------------------------- */
.home-banner {
	background-image: url(../images/pearl_bg.png); 
	background-size: 100% 100%;
}

.home-banner .cta {
	text-transform: uppercase;
	color: #062f6e;
	color: #0093C9;	
	font-weight: 600;
	font-size: large;
	text-decoration: none;
	z-index: 4;
}




/* 	------------------------------------------------------- */
/* 	Section 2 */
/* 	------------------------------------------------------- */
.img-pearl {
	width: 14rem;
}

.img-shadow {
	height: 100px;
}

.contact-cta {
	font-size: 1.5rem;
	padding: 4px 20px;
	margin: -1rem 2rem 5px 0;
	color: white;
	background-color: rgb(149, 225, 191);
	border-radius: 3px;
	cursor: pointer;
	transition: 0.25s;
	display: inline-block;
}

.contact-cta:hover {
	background-color: #062f6e;
	text-decoration: none;
	color: white;
}


/* 	------------------------------------------------------- */
/* video-header */
/* 	------------------------------------------------------- */
.video-header {
	position: relative;
	background-color: #185c98;
	background-size: cover;
	min-height: 22rem;
	max-height: 28rem;
	height: 45vh;
	width: 100%;
	overflow: hidden;
}

.video-header video {
	position: absolute;
	top: 50%;
	left: 50%;
	min-width: 100%;
	min-height: 100%;
	width: auto;
	height: auto;
	z-index: 0;
	transform: translateX(-50%) translateY(-50%);
}

.video-header .container {
	position: relative;
	z-index: 2;
}

.video-header h1 {
	opacity: 1.0;
	font-size: calc(3.375rem + 1.5vw);
	line-height: calc(3.375rem + 1.5vw);
	text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.25);
	font-family: "Barlow Semi Condensed", sans-serif;
	font-weight: 400;
}

.ec-section-2 {
	background-color: rgba(149, 225, 191, 0.5);
}

.video-text {
	display: inline-block;
	opacity: 1.0;
	font-size: calc(3.375rem + 1.5vw);
	line-height: calc(3.375rem + 1.5vw);
	font-family: "Barlow Semi Condensed", sans-serif;
	font-weight: 400;
	text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.25);
	transition: all 0.25s;
}

.video-text-hide {
	opacity: 0 !important;
}

.consulting-header {
	background-color: #c5eeff;
}

.marketing-header {
	background-color: #9eb9f0;
}


/* 	------------------------------------------------------- */
/* ec-section-3 */
/* 	------------------------------------------------------- */
.callout-list {
	color: #062f6e;
	transition: all .25s;
}

.callout-list.lighten {
	color: #062f6e;
}

.callout-list h3 {
	font-weight: 700;
}

/* 	------------------------------------------------------- */
/* ec-section-4 */
/* 	------------------------------------------------------- */
.ec-section-4 {
	position: relative;
	min-height: 22rem;
	background-size: cover;
	overflow: hidden;
}

.ec-section-4::before {
	position: absolute;
	content: " ";
	top: 0px;
	right: 0px;
	bottom: 0px;
	left: 0px;
	background-image: linear-gradient(to bottom, rgba(255, 255, 255, 1), 75%, rgba(255, 255, 255, 0));
	z-index: 2;
}

.ec-section-4-content {
	position: relative;
	z-index: 4;
}

.ec-section-4 .cta {
	text-transform: uppercase;
	color: #062f6e;
	font-weight: 600;
	font-size: large;
	text-decoration: none;
	z-index: 4;
}

.ec-section-4 .cta-em {
	color: #0093C9;
}

.ec-section-4 .cta-desc {
	font-size: smaller;
	font-weight: bold;
}

.ec-section-4 video {
	position: absolute;
	top: 50%;
	left: 50%;
	min-width: 100%;
	min-height: 100%;
	width: auto;
	height: auto;
	z-index: 0;
	transform: translateX(-50%) translateY(-50%);
}

/* 	------------------------------------------------------- */
/* ec-section-5 */
/* 	------------------------------------------------------- */
.ec-section-5 {
	position: relative;
	min-height: 20rem;
	background-size: cover;
	overflow: hidden;
}

.ec-section-5::before {
	position: absolute;
	content: " ";
	top: 0px;
	right: 0px;
	bottom: 0px;
	left: 0px;
	z-index: 2;
}

.ec-section-5-content {
	position: relative;
	z-index: 4;
}

.ec-section-5 video {
	position: absolute;
	top: 50%;
	left: 50%;
	min-width: 100%;
	min-height: 100%;
	width: auto;
	height: auto;
	z-index: 0;
	transform: translateX(-50%) translateY(-50%);
}

/* 	------------------------------------------------------- */
/* footer */
/* 	------------------------------------------------------- */
.ec-section-footer {
	height: 6rem;
	background-color: #062f6e;
	color: white;
	font-size: 1rem;
	font-weight: bold;
}

.ec-section-footer a {
	color: white;
	text-decoration: underline;
}

.header-circle {
	width: 14rem;
	height: 14rem;
	border-radius: 50%;
	opacity: .7;
	text-transform: uppercase;
	margin: auto;
	padding: 0 1rem;
	font-family: "Barlow Semi Condensed", sans-serif;
	font-weight: 500;
	font-size: 1.5rem;
	line-height: 1.5rem;
	transition: all .25s;
}

.header-circle:hover {
	opacity: 1.0;
}

.skills-copy {
	font-size: .9rem;
	font-weight: bold;
}

.grayscale-logo {
	background-size: contain;
	height: 120px;
	width: 90%;
	background-repeat: no-repeat;
	background-position-y: 50%;
	background-position-x: 50%;
}

.logoA {
	background-image: url(../images/logos/ADCT_Greys_TM-TAG.png);
}

.logoB {
	background-image: url(../images/logos/Athira.png);
}

.logoC {
	background-image: url(../images/logos/Combioxin.png);
}

.logoD {
	background-image: url(../images/logos/Nabriva.png);
}

.logoE {
	background-image: url(../images/logos/Pfizer.png);
}

.logoF {
	background-image: url(../images/logos/Neumora.png);
}

.logoG {
	background-image: url(../images/logos/Alairion.png);
}

.logoH {
	background-image: url(../images/logos/Blackthorn.png);
}

.logoI {
	background-image: url(../images/logos/JH.png);
}

.logoJ {
	background-image: url(../images/logos/ondosis.png);
}

.logoK {
	background-image: url(../images/logos/Salix.png);
}

.work-section-1 {
	background-image: url(../images/pearl_bg.png);
	background-size: cover;
	background-position: 50% 50%;
}

/* 	------------------------------------------------------- */
/* Contact Us */
/* 	------------------------------------------------------- */
.contact-us .header-circle {
	width: 15rem;
	height: 15rem;
	border-radius: 50%;
	font-weight: bold;
	opacity: 1;
	text-transform: uppercase;
	margin: auto;
	padding: 0 1rem;
	transition: all .25s;
	font-family: "Barlow Semi Condensed", sans-serif;
	font-weight: 500;
	font-size: 1.5rem;
	line-height: 1.5rem;
}

.contact-us .header-circle:hover {
	opacity: .7;
	border: 0px;
}

.contact-us .skills-copy {
	font-size: .9rem;
	font-weight: bold;
}

.contact-us .contact-head {
	font-family: "Barlow Semi Condensed", sans-serif;
	font-weight: 600;
	font-size: 1.5rem;
	line-height: 1.5rem;
}

.water-drop {
	list-style: none;
	line-height: 1.5rem;
	font-size: 1.5rem;
	font-size: 1.75rem;
	text-transform: uppercase;
	font-family: "Barlow Semi Condensed", sans-serif;
	font-weight: 500;
}

.water-drop li {
	margin-bottom: 2.5rem;
	margin-left: -2rem;
}

.water-drop li:last-child {
	margin-bottom: 0rem;
}

.contact-div {
	transition: all 1s;
}

.contact-hide {
	opacity: 0.15;
}

.contact-show {
	opacity: 1;
}

.contact-button {
	border: none;
}

.contact-button:hover {
	background-color: #062f6e;
}

.status-message {
	font-family: "Barlow Semi Condensed", sans-serif;
	font-weight: 600;
	font-size: 1.5rem;
	line-height: 1.5rem;
	text-transform: uppercase;
}

/* ----------------------------------------------------------------- */
/* Animated hamburger                                                */
/* https://mdbootstrap.com/docs/b4/jquery/navigation/hamburger-menu/ */
/* ----------------------------------------------------------------- */
.animated-icon2 {
	width: 30px;
	height: 20px;
	position: relative;
	margin: 0px;
	-webkit-transform: rotate(0deg);
	-moz-transform: rotate(0deg);
	-o-transform: rotate(0deg);
	transform: rotate(0deg);
	-webkit-transition: .5s ease-in-out;
	-moz-transition: .5s ease-in-out;
	-o-transition: .5s ease-in-out;
	transition: .5s ease-in-out;
	cursor: pointer;
}

.animated-icon2 span {
	display: block;
	position: absolute;
	height: 3px;
	width: 100%;
	border-radius: 9px;
	opacity: 1;
	left: 0;
	-webkit-transform: rotate(0deg);
	-moz-transform: rotate(0deg);
	-o-transform: rotate(0deg);
	transform: rotate(0deg);
	-webkit-transition: .25s ease-in-out;
	-moz-transition: .25s ease-in-out;
	-o-transition: .25s ease-in-out;
	transition: .25s ease-in-out;
}

.animated-icon2 span {
	/* background: #e3f2fd; */
	background: #062f6e !important;
}

.animated-icon2 span:nth-child(1) {
	top: 0px;
}

.animated-icon2 span:nth-child(2),
.animated-icon2 span:nth-child(3) {
	top: 10px;
}

.animated-icon2 span:nth-child(4) {
	top: 20px;
}

.animated-icon2.open span:nth-child(1) {
	top: 11px;
	width: 0%;
	left: 50%;
}

.animated-icon2.open span:nth-child(2) {
	-webkit-transform: rotate(45deg);
	-moz-transform: rotate(45deg);
	-o-transform: rotate(45deg);
	transform: rotate(45deg);
}

.animated-icon2.open span:nth-child(3) {
	-webkit-transform: rotate(-45deg);
	-moz-transform: rotate(-45deg);
	-o-transform: rotate(-45deg);
	transform: rotate(-45deg);
}

.animated-icon2.open span:nth-child(4) {
	top: 11px;
	width: 0%;
	left: 50%;
}

/* 	------------------------------------------------------- */
/* bubble area */
/* 	------------------------------------------------------- */
.video-header-bubbles {
	position: relative;
	background-color: #11669a;
	background-size: cover;
	width: 100%;
	overflow: hidden;
}

.video-header-bubbles video {
	position: absolute;
	top: 50%;
	left: 50%;
	min-width: 100%;
	min-height: 100%;
	width: auto;
	height: auto;
	z-index: 0;
	transform: translateX(-50%) translateY(-50%);
}

.video-header-bubbles .container {
	position: relative;
	z-index: 2;
}

.bubble-col {
	height: 7rem;
	display: flex;
}

.bubble-item {
	font-weight: bold;
	white-space: nowrap;
	margin: auto auto 0 auto;
	opacity: 0;
	transition-timing-function: ease-in-out;
	transition: all 5s;
	font-size: 1.75rem;
	font-family: "Barlow Semi Condensed", sans-serif;
	font-weight: 500;
	color: white;
	text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.25);
}

.bubble-item-float {
	transform: translate3D(0, -5rem, 0);
	opacity: .75;
}

.float-blue {
	font-size: 2.3rem;
}

@keyframes twinkle {
	0% { opacity: 0; }
	30% { opacity: 1; }
	60% { opacity: 0.75; }
	100% { top: 1; }
  }

/* ---------------------------------------------------------------------------- */
/* Navigation Menu - Mobile                                                    */
/* ---------------------------------------------------------------------------- */

/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {}


/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
	.logo {
		margin: 10px 0px 5px 0px;
		width: 240px;
	}
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) {}


/* // Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {}


@media (hover: hover) {

	.callout-list.lighten {
		opacity: .75;
		color: #8c87a3;
	}
}