// Expand delay values for AOS - Generate Duration && Delay
[data-aos] {
  @for $i from 1 through 90 {

    body[data-aos-duration='#{$i * 100}'] &,
    &[data-aos][data-aos-duration='#{$i * 100}'] {
      transition-duration: #{$i * 100}ms;
    }

    body[data-aos-delay='#{$i * 100}'] &,
    &[data-aos][data-aos-delay='#{$i * 100}'] {
      transition-delay: 0;

      &.aos-animate {
        transition-delay: #{$i * 100}ms;
      }
    }
  }
}
